<template>
  <network-container :loading="preLoading" :errors="errors">
    <template v-slot:shared>
      <go-back :title="pageTitle" />
    </template>
    <b-card>
      <validation-observer ref="form">
        <b-form class="mt-2">
          <b-row>
            <!-- NAME -->
            <b-col md="2" class="d-flex justify-content-center">
              <avatar-uploader
                :avatar="avatar"
                :delete-confirm="false"
                @change="selectAvatar"
                :editable="true"
              />
            </b-col>
            <b-col md="10">
              <b-row>
                <b-col xs="12" sm="12" md="6">
                  <b-form-group :label="$t('fields.name')" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="form.name"
                        name="name"
                        type="text"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- EMAIL -->
                <b-col xs="12" sm="12" md="6">
                  <b-form-group :label="$t('fields.email')" label-for="email">
                    <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required"
                    >
                      <b-form-input
                        id="email"
                        v-model="form.email"
                        name="email"
                        type="email"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- STATUS -->
                <b-col
                  xs="12"
                  sm="12"
                  md="6"
                  v-if="personnelId != $auth.user().id"
                >
                  <b-form-group :label="$t('fields.status')" label-for="status">
                    <validation-provider
                      #default="{ errors }"
                      name="status"
                      rules="required"
                    >
                      <v-select
                        v-model="form.status"
                        :options="statuses"
                        :reduce="(val) => val.value"
                        :clearable="false"
                        input-id="status"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
                <b-col cols="12" v-if="personnelId">
                  <b-button
                    icon="KeyIcon"
                    @click="() => resendPasswordLink(personnelId)"
                    permissions="user-update"
                    variant="outline-primary"
                    type="button"
                    size="sm"
                  >
                    {{ $t("personnel.actions.password.action") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col xs="12">
              <permission-manager
                :roles="form.roles"
                :permissions="form.permissions"
                :guard="form.guard_name"
                :editable="personnelId != $auth.user().id"
                :self="personnelId == $auth.user().id"
                @change="updateAcl"
                @set="setAcl"
              />
            </b-col>
          </b-row>

          <b-row class="mt-2">
            <b-col sm="12" md="4" class="ml-auto">
              <b-button
                variant="primary"
                block
                type="submit"
                @click.prevent="validateForm"
                :disabled="loading"
              >
                <b-spinner small v-if="loading" />
                <span class="sr-only" v-if="loading">
                  {{ $t("general.loading") }}</span
                >
                <span v-if="!loading">
                  {{
                    $t(
                      "personnel.actions." +
                        (this.personnelId ? "update" : "create")
                    )
                  }}</span
                >
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </network-container>
</template>

<script>
import { required, email } from "@validations";
import { show, store, update } from "@/api/users.api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import PermissionManager from "@/components/PermissionManager.vue";
import AvatarUploader from "../../../components/Person/AvatarUploader.vue";
import Spinner from "@/components/Spinner.vue";
import GoBack from "@/components/Common/GoBack.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { requestPasswordUpdate } from "@/api/auth.api";
import NetworkContainer from "@/components/NetworkContainer.vue";

export default {
  name: "personnel-form",
  components: {
    NetworkContainer,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    PermissionManager,
    AvatarUploader,
    Spinner,
    GoBack,
  },
  data() {
    return {
      preLoading: true,
      loading: false,
      errors: { notFound: false, serverError: false },
      form: {
        name: "",
        email: "",
        status: "",
        guard_name: "organization",
        permissions: [],
        roles: [],
      },
      avatar: null,
      avatarFile: null,
      statuses: [
        { label: this.$t("common.status.active"), value: "active" },
        { label: this.$t("common.status.passive"), value: "passive" },
      ],
      pageTitle: "Create User",
      required,
      email,
    };
  },
  computed: {
    personnelId() {
      return this.$route.params?.id || null;
    },
  },
  watch: {
    personnelId() {
      this.$refs.form.reset();
      if (this.personnelId) {
        this.fetchData();
      } else {
        this.preLoading = false;
      }
    },
  },
  methods: {
    async fetchData() {
      if (!this.personnelId) {
        this.form.name = null;
        this.form.email = null;
        this.preLoading = false;
        return;
      }

      this.preloading = true;
      const { data, error } = await show(this.personnelId);

      if (data) {
        Object.keys(this.form).map((key) => (this.form[key] = data[key]));
        this.avatar = data.avatar?.thumbnail;
        this.pageTitle = data.name;
      } else {
        this.errors.notFound = error.status == 404;
        this.errors.serverError = error.status == 500;
      }
      this.preLoading = false;
    },
    validateForm(redirect = true) {
      this.$refs.form.validate().then(async (success) => {
        if (!success) return;
        this.persist(redirect);
      });
    },
    async persist(redirect) {
      this.loading = true;

      const formData = new FormData();
      Object.keys(this.form).map((key) => {
        formData.append(key, this.form[key]);
      });

      if (this.avatarFile) {
        formData.append("avatar", this.avatarFile);
      }

      if (!this.avatar && !this.avatarFile) {
        formData.append("clear_avatar", true);
      }
      formData.append("organization_id", this.$auth.user().organization?.id);

      const { data, error } = this.personnelId
        ? await update(this.personnelId, formData)
        : await store(formData);

      if (error) {
        this.$refs.form.setErrors(error.invalid_params);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.title,
            icon: "XIcon",
            variant: "danger",
          },
        });
      } else if (data) {
        if (data.id && redirect) {
          this.$router.push({
            name: "personnel-show",
            params: { id: data.id },
          });
        }
      }

      this.loading = false;
    },
    updateAcl({ name, checked }) {
      this.form.permissions = checked
        ? [...this.form.permissions, name]
        : this.form.permissions.filter((n) => n != name);
    },
    setAcl({ permissions, roles }) {
      if (permissions) {
        this.form.permissions = permissions;
      }
      if (roles) {
        this.form.roles = roles;
      }
    },
    selectAvatar({ file }) {
      this.avatarFile = file;
      if (this.avatar && !file) {
        this.avatar = null;
      }
      if (this.avatarFile) {
        this.validateForm(false);
      }
    },
    async resendPasswordLink(id) {
      this.$swal({
        title: this.$t("auth.reset-password.title"),
        text: this.$t("auth.reset-password.modal.message"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("common.actions.confirm"),
        cancelButtonText: this.$t("subscription.actions.cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (!result.value) return;

        const { data, error } = await requestPasswordUpdate(id);

        if (data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("personnel.actions.password.success"),
              icon: "CheckIcon",
              variant: "success",
            },
          });
        }
        // TODO: genel hata veya yetki hatası
      });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>